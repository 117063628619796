import React from 'react';
import ReactPlayer from 'react-player/lazy';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	reactPlayer: {
		position: 'absolute',
		top: 0,
		left: 0,
    '& $video':{objectFit: 'cover'}
	},
}));

const Video = ({
	url,
	playing,
	loop = false,
	muted = false,
	controls = false,
	width,
	height,
}) => {
	const classes = useStyles();

	return (
		<ReactPlayer
			url={url}
			playing={playing}
			loop={loop}
			muted={muted}
			width={width}
			height={height}
			controls={controls}
			className={classes.reactPlayer}
		/>
	);
};

export default Video;
